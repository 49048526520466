import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import StyledLink from "@components/Link"
import Seo from "@components/Seo"

const AboutPage = ({ data }) => {
  const { pageMeta } = data

  return (
    <Layout>
      <Seo yoastSeo={pageMeta?.seo} />

      <section>
        <StaticImage
          src="../images/andrew-ng-full.jpeg"
          layout="fullWidth"
          alt="Andrew Ng"
          quality={100}
          className="md:h-[400px] lg:h-[450px]"
          height={450}
        />
      </section>
      <section className="mt-16 md:mt-24">
        <div className="container--boxed">
          <h1 className="h1">About</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-9 md:gap-20 mt-6">
            <div>
              <div className="prose dark:prose-invert">
                <p>
                  Dr. Andrew Ng is a globally recognized leader in AI
                  (Artificial Intelligence). He is Founder of
                  DeepLearning.AI, Founder &amp; CEO of Landing AI, General
                  Partner at AI Fund, Chairman &amp; Co-Founder of Coursera and
                  an Adjunct Professor at Stanford University’s Computer Science
                  Department.
                </p>

                <p>
                  In 2011, he led the development of Stanford University's main
                  MOOC (Massive Open Online Courses) platform and taught an
                  online Machine Learning course that was offered to over
                  100,000 students leading to the founding of Coursera where he
                  is currently Chairman and Co-founder.
                </p>

                <p>
                  Previously, he was Chief Scientist at Baidu, where he led the
                  company’s ~1300 person AI Group and was responsible for
                  driving the company’s global AI strategy and infrastructure.
                  He was also the founding lead of the Google Brain team.
                </p>

                <p>
                  As a pioneer in machine learning and online education, Dr. Ng
                  has changed countless lives through his work in AI, and has
                  authored or co-authored over 200 research papers in machine
                  learning, robotics and related fields. In 2023, he was named
                  to the Time100 AI list of the most influential AI persons in
                  the world. He holds degrees from Carnegie Mellon University, MIT
                  and the University of California, Berkeley.
                </p>

                <p>
                  Follow Dr.Ng on{" "}
                  <a
                    href="https://twitter.com/AndrewYNg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Twitter (@AndrewYNg)
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.linkedin.com/in/andrewyng/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Linkedin
                  </a>
                  .
                </p>
              </div>
            </div>
            <div>
              <StaticImage
                src="../images/andrew-ng-about-page.jpg"
                alt="Andrew Ng"
                width={524}
                height={564}
                quality={100}
                className="rounded"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-24 mb-20">
        <div className="container--boxed grid md:grid-cols-2 gap-16">
          <Company>
            <CompanyLogo>
              <StaticImage
                src="../images/landing-ai-logo.png"
                alt="Landing AI logo"
              />
            </CompanyLogo>
            <div>
              <div className="prose dark:prose-invert mb-4">
                <p>
                  Landing AI provides cutting-edge software that enables
                  reliable automated inspection for a wide range of applications
                  in industrial automation and manufacturing.
                </p>
              </div>
              <StyledLink
                variant="arrow"
                url={{
                  type: "external",
                  href: "https://landing.ai/",
                }}
              >
                Learn more
              </StyledLink>
            </div>
          </Company>
          <Company>
            <CompanyLogo>
              <StaticImage
                src="../images/deep-learning-ai-logo.svg"
                alt="DeepLearning AI logo"
              />
            </CompanyLogo>
            <div>
              <div className="prose dark:prose-invert mb-4">
                <p>
                  DeepLearning.AI is an education technology company that is
                  empowering the global workforce to build an AI-powered future
                  through world-class education, hands-on training, and a
                  collaborative community.
                </p>
              </div>
              <StyledLink
                variant="arrow"
                url={{
                  type: "external",
                  href: "https://www.deeplearning.ai/",
                }}
              >
                Learn more
              </StyledLink>
            </div>
          </Company>
          <Company>
            <CompanyLogo>
              <StaticImage
                src="../images/ai-fund-logo.png"
                alt="AI Fund logo"
              />
            </CompanyLogo>
            <div>
              <div className="prose dark:prose-invert mb-4">
                <p>
                  AI Fund is a venture capital firm that strives to move
                  humanity forward by accelerating the adoption of AI.
                </p>
              </div>
              <StyledLink
                variant="arrow"
                url={{
                  type: "external",
                  href: "https://aifund.ai/",
                }}
              >
                Learn more
              </StyledLink>
            </div>
          </Company>
        </div>
      </section>
    </Layout>
  )
}

const Company = ({ children }) => (
  <div className="grid grid-cols-1 gap-6 ">{children}</div>
)

const CompanyLogo = ({ children }) => (
  <div className="md:justify-self-start">{children}</div>
)

export default AboutPage

export const pageQuery = graphql`
  query WordPressAboutPage {
    pageMeta: wpPage(slug: { eq: "about" }) {
      ...WpPageFields
    }
  }
`
